"use strict";
/*!
 *
 *  gallery.ts
 *
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var Gallery = /** @class */ (function () {
    function Gallery(elem, option) {
        this.len = 0;
        this.default = {
            sliderClass: '.c-Gallery_Slider',
            playClass: '-play',
            pauseClass: '-pause'
        };
        this.$gallery = $(elem);
        this.setting = __assign(__assign({}, this.default), option);
        this.$slider = this.$gallery.find(this.setting.sliderClass);
        this.len = this.$slider.children().length;
        this.build();
        this.init();
    }
    Gallery.prototype.build = function () {
        var _this = this;
        var max = this.$slider.width();
        var itemW = 100 / this.len;
        var vw = max * 100 / 1000;
        this.$slider.css({
            'max-width': max + 'px',
            'min-width': 160 * this.len + 'px',
            'width': vw + 'vw'
        }).children().css({
            'width': itemW + '%'
        });
        var clones = [this.$slider.clone(true), this.$slider.clone(true)];
        clones.forEach(function (clone) {
            _this.$gallery.append(clone);
        });
        this.$slider = this.$gallery.find(this.setting.sliderClass);
    };
    Gallery.prototype.reBuild = function () {
        this.$slider.css({
            'max-width': 'none',
            'min-width': '0px',
            'width': 'auto'
        }).children().css({
            'width': 'auto'
        });
        var max = this.$slider.width();
        var itemW = 100 / this.len;
        var vw = max * 100 / 1000;
        this.$slider.css({
            'max-width': max + 'px',
            'min-width': 160 * this.len + 'px',
            'width': vw + 'vw'
        }).children().css({
            'width': itemW + '%'
        });
    };
    Gallery.prototype.init = function () {
        var _this = this;
        this.$gallery.on('mouseenter', function (e) {
            $(e.currentTarget).addClass(_this.setting.pauseClass);
        }).on('mouseleave', function (e) {
            $(e.currentTarget).removeClass(_this.setting.pauseClass);
        });
        this.$gallery.addClass(this.setting.playClass);
        $(window).on('resize', function () {
            _this.reBuild();
        });
    };
    return Gallery;
}());
$.fn.gallery = function (option) {
    var opt = option ? option : {};
    return this.each(function (i, elem) {
        new Gallery(elem, opt);
    });
};
