"use strict";
/*!
 *
 *  top.js
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1() {
        if ($('main').is('.tp-Main')) {
            this.hero();
        }
        ;
    }
    default_1.prototype.hero = function () {
        var $elem = $('#TopHeroGallery');
        if ($elem.length) {
            // let len = $elem.children('.tp-Hero_Gallery_Item').length;
            // $elem.on('lazyLoaded', () => {
            $elem.on('init', function (e) {
                $elem.attr('aria-hidden', 'false');
                setTimeout(function () {
                    $elem.slick('slickPlay');
                }, 10);
                setTimeout(function () {
                    $elem.find('.slick-active').addClass('slick-play');
                    $elem.slick('slickPlay');
                    // const svgPath = document.querySelectorAll('.path');
                    // svgPath.forEach((elem: Element) => {
                    // 	let offset = (elem as SVGPathElement).getTotalLength();
                    // 	$(elem).attr('')
                    // });
                    // const svgText = anime({
                    // 	targets: svgPath,
                    // 	loop: false,
                    // 	direction: 'normal',
                    // 	strokeDashoffset: [anime.setDashoffset, 0],
                    // 	easing: 'easeInOutSine',
                    // 	duration: 700,
                    // 	delay: (el, i) => { return i * 500 }
                    // });
                }, 700);
            })
                .on('beforeChange', function (e, slick, currentSlide, nextSlide) {
                $elem.find('.slick-slide').filter(function (i, elem) { return i == nextSlide; }).addClass('slick-play');
            }).on('afterChange', function () {
                $elem.find('.slick-slide').not('.slick-active').removeClass('slick-play');
            });
            $elem.slick({
                // lazyLoad: 'progressive',
                arrows: false,
                dots: true,
                fade: true,
                autoplay: false,
                autoplaySpeed: 3000,
                speed: 2000,
                pauseOnFocus: false,
                pauseOnHover: false,
                pauseOnDotsHover: false,
                waitForAnimate: false,
                appendDots: $('.tp-Hero_Dots')
            });
        }
        ;
    };
    return default_1;
}());
exports.default = default_1;
;
