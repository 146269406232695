"use strict";
/*!
 *
 *  scroll-anim.js
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
function default_1() {
    function getTargets() {
        var arr = [];
        var $elems;
        if (util.viewport !== 'phone') {
            $elems = $('.sw-SA, .sw-SA-pc');
        }
        else {
            $elems = $('.sw-SA, .sw-SA-sp');
        }
        ;
        $elems.each(function (i, elem) {
            arr.push({
                top: $(elem).offset().top,
                elem: elem
            });
        });
        return arr;
    }
    var targets = getTargets();
    var max = targets.length;
    $(window).on('scroll.scrollAnim', function (e) {
        var _top = $(e.currentTarget).scrollTop();
        targets.forEach(function (val) {
            if (!$(val.elem).is('.-run') && (_top + (util.wh / 2) + (util.wh / 3)) > val.top) {
                $(val.elem).addClass('-run');
                max--;
            }
            ;
            if (max === 0) {
                $(window).off('.scrollAnim');
            }
            ;
        });
    });
    $(window).on('resize.scrollAnim', function () {
        targets = getTargets();
    });
}
exports.default = default_1;
;
