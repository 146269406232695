"use strict";
/*!
 *
 *  widget.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1() {
        var _this = this;
        this.$widget = $('#Widget');
        this.$page2top = $('#Page2Top');
        this.$footer = $('#Footer');
        this.isStatic = false;
        this.isVisible = false;
        this.borderline = 0;
        this.underline = 0;
        this.$target = $('.sw-Target');
        this.borderline = this.getBorderline();
        this.underline = this.getUnderline();
        $(window).on('load resize', function () {
            _this.borderline = _this.getBorderline();
            _this.underline = _this.getUnderline();
        });
        setTimeout(function () {
            $(window).on('scroll', function (e) {
                _this.scroll(e);
            });
        }, 1000);
    }
    default_1.prototype.scroll = function (e) {
        var top = $(e.preventDefault).scrollTop();
        var bottom = top + util.wh;
        if (!this.isVisible && top > this.borderline) {
            this.isVisible = true;
            this.$widget.addClass('-visible');
            this.$page2top.addClass('-visible');
        }
        ;
        if (this.isVisible && top <= this.borderline) {
            this.isVisible = false;
            this.$widget.removeClass('-visible');
            this.$page2top.removeClass('-visible');
        }
        ;
        if (!this.isStatic && bottom >= this.underline) {
            this.isStatic = true;
            this.$page2top.addClass('-static');
        }
        ;
        if (this.isStatic && bottom < this.underline) {
            this.isStatic = false;
            this.$page2top.removeClass('-static');
        }
    };
    default_1.prototype.getUnderline = function () {
        return this.$footer.offset().top + this.$page2top.height() + (util.viewport !== 'phone' ? util.viewport !== 'tablet' ? 30 : 30 : 16);
    };
    default_1.prototype.getBorderline = function () {
        return this.$target.offset().top + this.$target.height();
    };
    return default_1;
}());
exports.default = default_1;
