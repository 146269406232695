"use strict";
/*!
 *
 *  top.js
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GradScroll = exports.Gallery = exports.Slider = void 0;
var Slider = /** @class */ (function () {
    function Slider() {
        var _this = this;
        this.slider = '.prod-List_Slider';
        $(window).on('viewportChanged', function () {
            if (util.viewport === 'phone') {
                $(_this.slider).slick('init');
            }
            ;
        });
        $(this.slider).each(function (i, elem) {
            var len = $(elem).children().length;
            var $control = $(elem).closest('.prod-List').children('.prod-List_Control');
            $(elem).on('breakpoint', function () {
                if (util.ww > 767) {
                    if (len <= 3) {
                        $control.addClass('-hide');
                    }
                    else {
                        $control.removeClass('-hide');
                    }
                    ;
                }
                else {
                    if (len <= 2) {
                        $control.addClass('-hide');
                    }
                    else {
                        $control.removeClass('-hide');
                    }
                    ;
                }
                ;
            });
            $(elem).on('init', function () {
                $(window).trigger('sliderLoad');
            });
            $(elem).slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                arrows: true,
                mobileFirst: true,
                centerMode: true,
                centerPadding: '0px',
                variableWidth: true,
                appendArrows: $control,
                prevArrow: '<button type="button" class="slick-prev">Prev</button>',
                nextArrow: '<button type="button" class="slick-next">Next</button>',
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            centerMode: false,
                            variableWidth: false
                        }
                    }
                ]
            });
            $(elem).trigger('breakpoint');
        });
    }
    return Slider;
}());
exports.Slider = Slider;
;
var Gallery = /** @class */ (function () {
    function Gallery() {
        this.gallery = '.prod-Gallery';
        $(this.gallery).each(function (i, elem) {
            var $main = $(elem).children('.prod-Gallery_Main');
            var $nav = $(elem).find('.prod-Gallery_Nav');
            var $summary = $('.prod-Summary');
            var init = [false, false, false];
            $main.on('init', function () {
                init[0] = true;
                if (init[1] && init[2]) {
                    $(elem).addClass('-inited');
                }
                ;
            });
            $nav.on('init', function () {
                init[1] = true;
                if (init[0] && init[2]) {
                    $(elem).addClass('-inited');
                }
                ;
            });
            $summary.on('init', function () {
                init[2] = true;
                if (init[0] && init[1]) {
                    $(elem).addClass('-inited');
                }
                ;
            });
            $main.attr('id', 'PGM' + i);
            $nav.attr('id', 'PGN' + i);
            $main.slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                fade: true,
                asNavFor: '#PGN' + i
            });
            $nav.slick({
                slidesToShow: 5,
                slidesToScroll: 1,
                arrows: false,
                focusOnSelect: true,
                asNavFor: '#PGM' + i + ', .prod-Summary'
            });
            $summary.slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                fade: true,
                asNavFor: '#PGN' + i,
                adaptiveHeight: true
            });
            $(window).on('resize', function () {
                $main.slick('reinit');
                $nav.slick('reinit');
                $main.slick('reinit');
            });
        });
    }
    return Gallery;
}());
exports.Gallery = Gallery;
;
var GradScroll = /** @class */ (function () {
    function GradScroll() {
        var _this = this;
        this.$elem = $('.prod-Header_Scroll');
        this.$scroll = this.$elem.children('.prod-Header_Scroll_Inner');
        $(window).on('resize', function () {
            _this.set();
        });
        this.set();
        this.$elem.addClass('-inited');
    }
    GradScroll.prototype.set = function () {
        this.$scroll.css({
            'max-height': 0,
            'overflow': 'hidden'
        });
        var h = this.$elem.height();
        this.$scroll.css({
            'max-height': h,
            'overflow': 'scroll'
        });
    };
    return GradScroll;
}());
exports.GradScroll = GradScroll;
